import React from 'react';
import autoBind from 'react-autobind';
import { Layout, Drawer, Form, Tooltip, Popconfirm, Divider, Button, Table, message, Spin } from 'antd';
//
import Utils from '../../../components/Utils';
import CustomComponent from '../../../components/CustomComponent';
import DateRangeFilter from '../../../components/DateRangeFilter';
import CommonLoadingView from '../CommonLoadingView';
import Globals from '../../../config/Globals';
//
//props are: onMove, app, session
export default class CommonRescheduleSessionActionDrawer extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      sessions: [],
      userID: null,
      userFullName: null,
      newSessionID: null,
      selectedSession: false,
      isLoading: false,
      isProcessing: false,
      isVisible: false,
    };
  }

  // Public
  async show(userID) {
    this.setState({
      sessions: [],
      selectedSession: false,
      userID: userID,
      isVisible: true,
    });
  }

  // Private actions
  handleClose() {
    this.setState({ isVisible: false, isLoading: false, isProcessing: false });
  }

  async handleFilter(dates) {
    let [from, to] = dates || [];
    if (!from || !to) {
      this.setState({ sessions: [] });
      return;
    }
    to = to.toDate().getTime();
    from = from.toDate().getTime();
    this.setState({ sessions: [], isLoading: true });

    const resp = await this.props.app.classroom.session.getSessionList(from, to, this.props.session.courseID);
    if (resp.statusCode == 200 && resp.body && resp.body.sessions) {
      this.setState({
        sessions: resp.body.sessions.filter((s) => s.id !== this.props.session.id),
        isLoading: false,
      });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.setState({ sessions: [], isLoading: false });
    }
  }

  async handleSubmit() {
    const resp = await this.props.app.classroom.session.moveStudentSession(
      this.props.session.id,
      this.state.userID,
      this.state.newSessionID
    );

    if (resp.statusCode === 200 && resp.body) {
      this.setState({ isProcessing: true });
      await Utils.sleep(10000);
      this.handleClose();
      this.setState({ isProcessing: false });
      window.location.reload();
      message.success('Rescheduled successfully!');
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
  }

  _getCityAndVenueNames(session) {
    const venue = this.props.app.sharedCache().getVenueByID(session?.venueID);
    const city = this.props.app.sharedCache().getCityByID(venue?.cityID);
    const cityName = city ? `${city.name}: ` : '';
    return `${cityName}${venue?.name || '-'}`;
  }

  // UI
  render() {
    return (
      <Drawer
        open={this.state.isVisible}
        title="Select a new session to move"
        placement="right"
        onClose={this.handleClose}
        width={900}
      >
        {this.state.isProcessing ? (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
            <Spin size="large" />
            <p style={{ marginTop: '15px' }}>This operation will take a few seconds, please do not navigate away.</p>
          </div>
        ) : (
          <>
            <DateRangeFilter onFilter={this.handleFilter} />
            <CommonLoadingView isLoading={this.state.isLoading} isFixed />
            {this.state.sessions.length > 0 && (
              <>
                <Divider>Sessions</Divider>
                {this._renderSessionsMoveOperationTable()}
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Tooltip placement="bottomLeft" title="Move to selected session">
                    <Popconfirm
                      placement="top"
                      title="You will be removed from your current session and enrolled in the newly selected one. Confirm operation?"
                      onConfirm={this.handleSubmit.bind(this)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button type="primary" disabled={!this.state.selectedSession} style={{ margin: '15px 0' }}>
                        Move
                      </Button>
                    </Popconfirm>
                  </Tooltip>
                </div>
              </>
            )}
          </>
        )}
      </Drawer>
    );
  }

  /* private UI */
  _renderSessionsMoveOperationTable() {
    const courses = this.props.app.sharedCache().getAllUniqueCourses();

    const columns = [
      {
        title: 'Type',
        key: 'type',
        dataIndex: 'type',
        width: '30%',
        render: (props) => Globals.getTemplateTypeIcon(props, true),
      },
      {
        title: 'Venue',
        key: 'venue',
        dataIndex: 'venue',
        width: '30%',
        render: (text, record) => this._getCityAndVenueNames(record),
      },
      {
        title: 'Dates',
        key: 'dates',
        width: '30%',
        render: (props) => {
          if (props.startDate && props.startDate.length > 0)
            return props.startDate.map((s) => Utils.getDateAndTimeOnUIFormatByTimestamp(s)).join(', ');
          else return '-';
        },
      },
    ];

    const props = {
      rowKey: 'id',
      loading: this.state.isLoading,
      locale: { emptyText: 'No session found!' },
    };
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({ newSessionID: selectedRowKeys[0], selectedSession: true });
      },
    };
    return (
      <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
        <Layout.Content>
          <Table
            columns={columns}
            dataSource={this.state.sessions || []}
            {...props}
            pagination={false}
            rowSelection={{
              type: 'radio',
              ...rowSelection,
            }}
          />
        </Layout.Content>
      </Form>
    );
  }
}
