import React from 'react';
import autoBind from 'react-autobind';
import { Layout, PageHeader, message, Tabs, Row, Col, Button, Popconfirm, Tooltip } from 'antd';
import {
  StopOutlined,
  DeleteOutlined,
  LockOutlined,
  UnlockOutlined,
  ReloadOutlined,
  EditOutlined,
  WarningOutlined,
  UndoOutlined,
} from '@ant-design/icons';
import scrollIntoView from 'scroll-into-view';
//
import config from '../../../config/config';
import Utils from '../../../components/Utils';
import Globals from '../../../config/Globals';
//
import CustomComponent from '../../../components/CustomComponent';
import CommonLoadingView from '../../commonComponents/CommonLoadingView';
import WhiteBox from '../../commonComponents/WhiteBox';
//
import CommonSessionReadForm from '../../commonComponents/Forms/CommonSessionReadForm';
import CommonSessionInstructorsForm from '../../commonComponents/FakeForms/CommonSessionInstructorsForm';
import CommonSessionInvitationsForm from '../../commonComponents/FakeForms/CommonSessionInvitationsForm';
import CommonSessionStudentsForm from '../../commonComponents/FakeForms/CommonSessionStudentsForm';
import CommonSessionDocumentsForm from '../../commonComponents/FakeForms/CommonSessionDocumentsForm';
import CommonSessionResultModal from '../../commonComponents/Modals/CommonSessionResultModal';
import CommonLicensePurchaseModal from '../../commonComponents/Modals/CommonLicensePurchaseModal';
//
import CommonSessionMoveOperationDrawer from '../../commonComponents/Drawers/CommonSessionMoveOperationDrawer';
import CommonSessionChargesDrawer from '../../commonComponents/Drawers/CommonSessionChargesDrawer';
import CommonSessionReplaceStudentDrawer from '../../commonComponents/Drawers/CommonSessionReplaceStudentDrawer';
//
const RequestMask = { SESSION: 2, VENUES: 4, INSTRUCTORS: 8, CITIES: 16, ALL: 2 | 4 | 8 | 16 };
//
export default class AdminSessionView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    const sessionID = this.props.match.params.id;
    this.state = {
      isLoading: false,
      sessionID,
      venues: null,
      courses: null,
      instructors: null,
      session: null,
      selectedTab: 'main',
      invitationFailures: [],
      isPurchaseModalVisible: false,
      modalUser: null,
    };
  }

  //View Life Cycle
  async componentDidMount() {
    super.componentDidMount();
    //Load data
    await this.fetchData(RequestMask.ALL);
    //Tab
    const selectedTab = this.props.app.idm.urlmanager.getParam(Globals.URL_Path_TabID);
    if (this.state.selectedTab != selectedTab && selectedTab) this.handleTabChange(selectedTab);
    else this.handleTabChange(this.state.selectedTab);
    //Scroll
    this._scrollToTop();
  }

  handleReloadList() {
    this.fetchData(RequestMask.ALL);
  }

  async fetchData(mask) {
    this.startLoading();
    const resp = await this._loadData(mask || RequestMask.ALL);
    if (resp) {
      this.setState({ ...resp, isLoading: false }, () => {
        if (this.state.sessionID && this.state.session) this.sessionForm.setFieldsValue(this.state.session);
      });
    } else this.stopLoading();
  }

  //Actions
  handleTabChange(selectedTab) {
    this.setState({ selectedTab }, () => {
      this.props.app.urlManager.updateQueryStringParam(Globals.URL_Path_TabID, selectedTab);
    });
  }
  //Main Tab actions
  handleSessionEdit() {
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.sessionEdit, null, this.state.sessionID);
  }
  async handleSessionDelete() {
    this.startLoading();
    const resp = await this._deleteSession();
    if (resp) this.props.app.urlManager.replacePage(config.ApplicationRoutes.sessions);
    this.stopLoading();
  }
  handleSessionCancel() {
    this._execSessionOperation(this._cancelSession());
  }
  async handleSessionNext(actionName) {
    let execOperation = true;
    if (this._checkObligationWhenComplete(actionName)) {
      execOperation = await this._confirmRequiresInstructor();
    }
    if (execOperation) this._execSessionOperation(this._transitionSession(actionName));
  }
  handleSessionLock() {
    this._execSessionOperation(this._lockSession());
  }
  handleSessionUnlock() {
    this._execSessionOperation(this._unlockSession());
  }
  handleSessionPublishToggle() {
    this._execSessionOperation(this._tooglePublishSession());
  }
  //Instructor Tab actions
  handleInstructorAdd(instructor, type) {
    this._execSessionOperation(this._addInstructor(instructor, type));
  }
  handleInstructorDeletion(userID) {
    this._execSessionOperation(this._removeInstructor(userID));
  }
  //Invitation Tab actions
  handleBatchInvite(invites) {
    this._execSessionOperation(this._batchInvite(invites));
  }
  handleInvitationDeletion(invitationID) {
    this._execSessionOperation(this._removeInvitation(invitationID));
  }
  //Student Tab actions
  handleSessionEnrolmentRelease(enrolment) {
    this._execSessionOperation(this._enrolmentRelease(enrolment.userID, enrolment.externalID));
  }
  handleSessionEnrolmentLaunch(enrolment) {
    this._execSessionOperation(this._enrolmentLaunch(enrolment.userID, enrolment.externalID));
  }
  handleSessionEnrolmentCheckGrade(enrolment) {
    this._execSessionOperation(this._enrolmentCheckGrade(enrolment.userID, enrolment.externalID));
  }
  //sessionID, courseSpecsID, userObj, enrolment, attendance
  handleSessionAddResult(enrolment) {
    this.sessionResultModal.show(this.state.sessionID, this.state.session.courseID, enrolment);
  }
  handleSessionUpdateResult(enrolment, result, attendance) {
    this.sessionResultModal.show(this.state.sessionID, this.state.session.courseID, enrolment, result, attendance);
  }
  handleSessionDeleteResult(enrolment, result, attendance) {
    this._execSessionOperation([
      result ? this._deleteResult(enrolment.userID, enrolment.externalID) : Promise.resolve(true),
      attendance ? this._deleteAttendance(enrolment.userID, enrolment.externalID) : Promise.resolve(true),
    ]);
  }
  handleSessionEnrolmentShippingUpsert() {
    message.warning('Not Implemented!');
  }
  handleSessionDeleteEnrolment(enrolment) {
    this._execSessionOperation(this._enrolmentDelete(enrolment.userID, enrolment.externalID));
  }
  handleSessionCancelEnrolment(enrolment) {
    this._execSessionOperation(this._enrolmentCancel(enrolment.userID, enrolment.externalID));
  }
  handleSessionEnrolmentsRelease() {
    this._execSessionOperation(this._releaseSession());
  }
  handleSessionRefreshShippingInfo() {
    this._execSessionOperation(this._refreshSessionShippingInformation());
  }
  //Session Move Operation
  handleSessionEnrolmentSessionMoveOperation(student) {
    this.SessionMoveOperationDrawer.show(student.userID);
  }
  handleSessionEnrolmentSessionChargesAttempts(enrolment) {
    this.SessionChargesDrawer.show(enrolment.sessionID, enrolment.userID);
  }
  handleSessionMoveOperation() {
    this.handleReloadList();
  }
  //Session Charge Operation
  async handleSessionCharge(enrolment, certificationProcess, orgID) {
    if (enrolment) {
      const courseSpecs = this.props.app.sharedCache().getCourseByID(enrolment.courseID);
      //Get org info
      let user = {
        id: enrolment.userID,
        firstName: enrolment.firstName,
        lastName: enrolment.lastName,
        email: enrolment.email,
        certificationProcess,
        enrolment,
      };
      let org = null;
      if (orgID) {
        org = await this._getOrgData(orgID);
        user = { ...user, orgCharge: true };
      }
      if (!this.state.isPurchaseModalVisible) {
        this.purchaseModal.loadModalInfo(courseSpecs.productID, null, courseSpecs);
        this.purchaseModal.setSessionID(this.state.sessionID);
      }
      this.setState((prevState) => ({
        isPurchaseModalVisible: !prevState.isPurchaseModalVisible,
        modalUser: user,
        modalOrg: org,
      }));
    } else {
      this.setState(
        (prevState) => ({
          isPurchaseModalVisible: !prevState.isPurchaseModalVisible,
          modalUser: null,
        }),
        () => this.handleReloadList()
      );
    }
  }
  //Replace Student Operation
  handleReplaceStudent(student) {
    this.SessionReplaceStudentDrawer.show(student);
  }
  //Export Shipping Manifest XLSX
  async handleExportShippingManifest(updateShippingInfo) {
    this.startLoading();
    const sessionID = this.state.session?.id;
    const resp = await this.props.app.classroom.report.sessionShippingManifest(sessionID, updateShippingInfo);
    if (resp.statusCode == 200) {
      Utils.downloadBlob(resp.body, 'session-shipping-manifest', 'xlsx');
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
    this.stopLoading();
  }

  //UI
  render() {
    const capacity = this.state.session?.capacity;
    const fullSession = capacity == -1;
    const enrolments = this.state.session?.enrolments?.length;

    const studentTabTitle = fullSession ? `Students (${enrolments})` : `Students (${enrolments}/${capacity})`;
    return (
      <>
        <Layout.Content className="pageContent scrollHere">
          <CommonLoadingView isLoading={this.state.isLoading} />
          <CommonSessionResultModal
            {...Utils.propagateRef(this, 'sessionResultModal')}
            app={this.props.app}
            onChange={this.fetchData}
            session={this.state.session}
            showEnrolment={true}
          />
          <CommonLicensePurchaseModal
            {...Utils.propagateRef(this, 'purchaseModal')}
            user={this.state.modalUser}
            certification={this.state.modalUser?.certificationProcess}
            enrolment={this.state.modalUser?.enrolment}
            org={this.state.modalOrg}
            isVisible={this.state.isPurchaseModalVisible}
            fixedQuantity
            sessionCharge
            onChange={this.handleSessionCharge}
            app={this.props.app}
            onRequiresAttention={(modal) => {
              this.purchaseModal = modal;
              this.handleSessionCharge();
            }}
          />
          <PageHeader
            className="pageHeader"
            title={'Session'}
            onBack={() =>
              this.props.app.urlManager.pushBack() ||
              this.props.app.urlManager.pushPage(config.ApplicationRoutes.sessions)
            }
            subTitle={
              <Tooltip title="Refresh">
                <Button icon={<ReloadOutlined />} onClick={this.handleReloadList} />
              </Tooltip>
            }
          />
          <WhiteBox>
            <Tabs activeKey={this.state.selectedTab} onChange={this.handleTabChange}>
              <Tabs.TabPane tab="Session" key="main">
                <Row type="flex" justify="end" style={{ marginBottom: 20 }}>
                  {' '}
                  <Col> {this._renderMainTabActions()} </Col>{' '}
                </Row>
                <CommonSessionReadForm
                  app={this.props.app}
                  session={this.state.session}
                  venues={this.state.venues}
                  courses={this.state.courses}
                  {...Utils.propagateRef(this, 'sessionForm')}
                  onPublishToogle={this.handleSessionPublishToggle}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab={studentTabTitle} key="students">
                {this._renderStudentTabActions()}
              </Tabs.TabPane>
              {this.props.app.isAdmin() && (
                <Tabs.TabPane tab="Instructors" key="instructors">
                  <CommonSessionInstructorsForm
                    app={this.props.app}
                    session={this.state.session}
                    instructors={this.state.instructors}
                    onSelect={this.handleInstructorAdd}
                    onDelete={this.handleInstructorDeletion}
                  />
                </Tabs.TabPane>
              )}
              {this.props.app.isAdmin() && (
                <Tabs.TabPane tab="Invitations" key="invitations">
                  <CommonSessionInvitationsForm
                    {...Utils.propagateRef(this, 'sessionInvitation')}
                    app={this.props.app}
                    session={this.state.session}
                    onInvite={this.handleBatchInvite}
                    onDelete={this.handleInvitationDeletion}
                    failures={this.state.invitationFailures}
                  />
                </Tabs.TabPane>
              )}
              {this.props.app.isAdmin() && (
                <Tabs.TabPane tab="Documents" key="documents">
                  <CommonSessionDocumentsForm app={this.props.app} session={this.state.session} />
                </Tabs.TabPane>
              )}
            </Tabs>
          </WhiteBox>
        </Layout.Content>
      </>
    );
  }
  /* private UI */
  _renderMainTabActions() {
    if (this.state.session?.state == Globals.Session_State.DRAFT && this.props.app.isAdmin()) {
      //admin only
      return (
        <>
          <Popconfirm
            placement="top"
            title={
              <>
                <span>Once you make this session available, it will become available for students to enrol.</span>
                <br />
                <span>Make the session available?</span>
              </>
            }
            onConfirm={this.handleSessionNext.bind(this, 'released')}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary">Make Available</Button>
          </Popconfirm>
          <Button type="secondary" style={{ marginLeft: 15 }} onClick={this.handleSessionEdit}>
            Edit
          </Button>
          <Popconfirm
            placement="top"
            title={`Are you sure that you want to delete this session?`}
            onConfirm={this.handleSessionDelete.bind(this)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="secondary" style={{ marginLeft: 15 }}>
              {' '}
              <DeleteOutlined style={{ marginRight: 7 }} /> Delete
            </Button>
          </Popconfirm>
          {this.state.session?.hasMaterial != Globals.Session_HasMaterial.NONE && (
            <Popconfirm
              placement="top"
              title={`Depending on the number of students, this operation may take a long time, confirm?`}
              onConfirm={this.handleSessionRefreshShippingInfo.bind(this)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="secondary" style={{ marginLeft: 15 }}>
                {' '}
                <UndoOutlined />
                Refresh Shipping
              </Button>
            </Popconfirm>
          )}
          {this.state.session?.hasMaterial != Globals.Session_HasMaterial.NONE && (
            <Popconfirm
              placement="top"
              title={`Would you like to refresh the shipping information for the session before the export?`}
              onConfirm={() => this.handleExportShippingManifest(true)}
              onCancel={() => this.handleExportShippingManifest(false)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="secondary" style={{ marginLeft: 15 }}>
                Export Manifest
              </Button>
            </Popconfirm>
          )}
        </>
      );
    } else if (
      (this.state.session?.state == Globals.Session_State.PENDING_INSTRUCTOR ||
        this.state.session?.state == Globals.Session_State.PENDING_ADMIN) &&
      this.props.app.isAdmin()
    ) {
      //admin only
      console.log('*-*-*- this.state.session', this.state.session);
      return (
        <>
          <Popconfirm
            placement="top"
            title={`Are you sure that you want to lock this session?`}
            onConfirm={this.handleSessionLock.bind(this)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="secondary" style={{ marginLeft: 15 }}>
              {' '}
              <LockOutlined style={{ marginRight: 7 }} /> Lock
            </Button>
          </Popconfirm>
          <Popconfirm
            placement="top"
            title={`Are you sure that you want to cancel this session?`}
            onConfirm={this.handleSessionCancel.bind(this)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="secondary" style={{ marginLeft: 15 }}>
              {' '}
              <StopOutlined style={{ marginRight: 7 }} /> Cancel
            </Button>
          </Popconfirm>
          <Popconfirm
            placement="top"
            title={
              <>
                <span>
                  This session is pending review. Attemps to close it without all attendance and grades informed will
                  cause this operation to fail.
                </span>
                <br />
                <span>Close the session?</span>
              </>
            }
            onConfirm={this.handleSessionNext.bind(this, 'closed')}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary">Close Session</Button>
          </Popconfirm>
          {this.state.session?.hasMaterial != Globals.Session_HasMaterial.NONE && (
            <Popconfirm
              placement="top"
              title={`Depending on the number of students, this operation may take a long time, confirm?`}
              onConfirm={this.handleSessionRefreshShippingInfo.bind(this)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="secondary" style={{ marginLeft: 15 }}>
                {' '}
                <UndoOutlined />
                Refresh Shipping
              </Button>
            </Popconfirm>
          )}
          {this.state.session?.hasMaterial != Globals.Session_HasMaterial.NONE && (
            <Popconfirm
              placement="top"
              title={`Would you like to refresh the shipping information for the session before the export?`}
              onConfirm={() => this.handleExportShippingManifest(true)}
              onCancel={() => this.handleExportShippingManifest(false)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="secondary" style={{ marginLeft: 15 }}>
                Export Manifest
              </Button>
            </Popconfirm>
          )}
        </>
      );
    } else if (this.state.session?.state == Globals.Session_State.LOCKED && this.props.app.isAdmin()) {
      //admin only
      return (
        <>
          <Popconfirm
            placement="top"
            title={`Are you sure that you want to unlock this session?`}
            onConfirm={this.handleSessionUnlock.bind(this, 'unlocked')}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary" style={{ marginLeft: 15 }}>
              {' '}
              <UnlockOutlined style={{ marginRight: 7 }} /> Unlock
            </Button>
          </Popconfirm>
          <Button type="primary" style={{ marginLeft: 15 }} onClick={this.handleSessionEdit}>
            {' '}
            <EditOutlined style={{ marginRight: 7 }} /> Update
          </Button>
          <Popconfirm
            placement="top"
            title={`Are you sure that you want to cancel this session?`}
            onConfirm={this.handleSessionCancel.bind(this)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="secondary" style={{ marginLeft: 15 }}>
              {' '}
              <StopOutlined style={{ marginRight: 7 }} /> Cancel
            </Button>
          </Popconfirm>
          {this.state.session?.hasMaterial != Globals.Session_HasMaterial.NONE && (
            <Popconfirm
              placement="top"
              title={`Depending on the number of students, this operation may take a long time, confirm?`}
              onConfirm={this.handleSessionRefreshShippingInfo.bind(this)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="secondary" style={{ marginLeft: 15 }}>
                {' '}
                <UndoOutlined />
                Refresh Shipping
              </Button>
            </Popconfirm>
          )}
          {this.state.session?.hasMaterial != Globals.Session_HasMaterial.NONE && (
            <Popconfirm
              placement="top"
              title={`Would you like to refresh the shipping information for the session before the export?`}
              onConfirm={() => this.handleExportShippingManifest(true)}
              onCancel={() => this.handleExportShippingManifest(false)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="secondary" style={{ marginLeft: 15 }}>
                Export Manifest
              </Button>
            </Popconfirm>
          )}
        </>
      );
    } else if (this.state.session?.state == Globals.Session_State.AVAILABLE) {
      const allResultsFulfilled = this.state.session?.enrolments?.length == this.state.session?.results?.length;
      if (this.props.app.isAdmin()) {
        console.log('*-*-*- this.state.session', this.state.session);
        return (
          <>
            <Popconfirm
              placement="top"
              title={`Are you sure that you want to lock this session?`}
              onConfirm={this.handleSessionLock.bind(this)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary">
                {' '}
                <LockOutlined style={{ marginRight: 7 }} /> Lock
              </Button>
            </Popconfirm>
            <Popconfirm
              placement="top"
              title={
                <>
                  ⚠️ Warning: This operation cannot be reversed.<br></br>Are you sure that you want to complete this
                  session?
                </>
              }
              onConfirm={this.handleSessionCancel.bind(this)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="secondary" style={{ marginLeft: 15 }}>
                {' '}
                <StopOutlined style={{ marginRight: 7 }} /> Cancel
              </Button>
            </Popconfirm>
            {!allResultsFulfilled ? (
              <Popconfirm
                placement="right"
                title={
                  <>
                    ⚠️ Warning: Not all students registered in this session have results assigned. If you complete
                    <br></br> this session now you will no longer be able to update grades for students in this session.
                    <br></br>Continue with this operation ?
                  </>
                }
                onConfirm={this.handleSessionNext.bind(this, 'completed')}
                okText="Yes"
                cancelText="No"
                zIndex={1}
              >
                <Button type="secondary" style={{ marginLeft: 15 }}>
                  {' '}
                  <WarningOutlined style={{ marginRight: 7 }} /> Complete
                </Button>
              </Popconfirm>
            ) : (
              <Popconfirm
                placement="top"
                title={
                  <>
                    ⚠️ Warning: This operation cannot be reversed.<br></br> Ensure no more students will be added to
                    this session and that all grades have been entered before you proceed. Once a session is completed,
                    it can no longer be edited in any way.<br></br>Are you sure that you want to complete this session?
                  </>
                }
                onConfirm={this.handleSessionNext.bind(this, 'completed')}
                okText="Yes"
                cancelText="No"
              >
                <Button type="secondary" style={{ marginLeft: 15 }}>
                  <WarningOutlined style={{ marginRight: 7 }} /> Complete
                </Button>
              </Popconfirm>
            )}
            {this.state.session?.hasMaterial != Globals.Session_HasMaterial.NONE && (
              <Popconfirm
                placement="top"
                title={`Depending on the number of students, this operation may take a long time, confirm?`}
                onConfirm={this.handleSessionRefreshShippingInfo.bind(this)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="secondary" style={{ marginLeft: 15 }}>
                  {' '}
                  <UndoOutlined />
                  Refresh Shipping
                </Button>
              </Popconfirm>
            )}
            {this.state.session?.hasMaterial != Globals.Session_HasMaterial.NONE && (
              <Popconfirm
                placement="top"
                title={`Would you like to refresh the shipping information for the session before the export?`}
                onConfirm={() => this.handleExportShippingManifest(true)}
                onCancel={() => this.handleExportShippingManifest(false)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="secondary" style={{ marginLeft: 15 }}>
                  Export Manifest
                </Button>
              </Popconfirm>
            )}
          </>
        );
      } else if (this.props.app.isInstructor()) {
        return (
          <>
            {!allResultsFulfilled ? (
              <Popconfirm
                placement="right"
                title={
                  <>
                    ⚠️ Warning: Not all students registered in this session have results assigned. If you complete
                    <br></br> this session now you will no longer be able to update grades for students in this session.
                    <br></br>Continue with this operation ?
                  </>
                }
                onConfirm={this.handleSessionNext.bind(this, 'completed')}
                okText="Yes"
                cancelText="No"
              >
                <Button type="primary">Complete</Button>
              </Popconfirm>
            ) : (
              <Popconfirm
                placement="top"
                title={`Are you sure that you want to complete this session?`}
                onConfirm={this.handleSessionNext.bind(this, 'completed')}
                okText="Yes"
                cancelText="No"
              >
                <Button type="primary">Complete</Button>
              </Popconfirm>
            )}
          </>
        );
      }
    } else if (this.state.session?.state == Globals.Session_State.COMPLETED && this.props.app.isAdmin()) {
      //admin only
      return (
        <>
          {this.state.session?.hasMaterial != Globals.Session_HasMaterial.NONE && (
            <>
              {this.state.session?.hasMaterial != Globals.Session_HasMaterial.NONE && (
                <Popconfirm
                  placement="top"
                  title={`Depending on the number of students, this operation may take a long time, confirm?`}
                  onConfirm={this.handleSessionRefreshShippingInfo.bind(this)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="secondary" style={{ marginLeft: 15 }}>
                    {' '}
                    <UndoOutlined />
                    Refresh Shipping
                  </Button>
                </Popconfirm>
              )}
              <Popconfirm
                placement="top"
                title={`Would you like to refresh the shipping information for the session before the export?`}
                onConfirm={() => this.handleExportShippingManifest(true)}
                onCancel={() => this.handleExportShippingManifest(false)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="secondary" style={{ marginLeft: 15 }}>
                  Export Manifest
                </Button>
              </Popconfirm>
            </>
          )}
        </>
      );
    } else if (this.state.session?.state == Globals.Session_State.CANCELLED && this.props.app.isAdmin()) {
      //admin only
      //nothing for now
    }
    return <></>;
  }

  _renderStudentTabActions() {
    return (
      <>
        <CommonSessionMoveOperationDrawer
          {...Utils.propagateRef(this, 'SessionMoveOperationDrawer')}
          app={this.props.app}
          session={this.state.session}
          onMove={this.handleSessionMoveOperation}
        />
        <CommonSessionChargesDrawer
          {...Utils.propagateRef(this, 'SessionChargesDrawer')}
          app={this.props.app}
          session={this.state.session}
        />
        <CommonSessionReplaceStudentDrawer
          {...Utils.propagateRef(this, 'SessionReplaceStudentDrawer')}
          app={this.props.app}
          session={this.state.session}
          onReplace={this.handleReloadList}
        />
        <CommonSessionStudentsForm
          app={this.props.app}
          session={this.state.session}
          onEnrolmentRelease={this.handleSessionEnrolmentRelease}
          onLaunch={this.handleSessionEnrolmentLaunch}
          onCheckGrade={this.handleSessionEnrolmentCheckGrade}
          onAddManualResult={this.handleSessionAddResult}
          onSessionMoveOperation={this.handleSessionEnrolmentSessionMoveOperation}
          onSessionChargesAttempts={this.handleSessionEnrolmentSessionChargesAttempts}
          onUpdateResult={this.handleSessionUpdateResult}
          onDeleteResult={this.handleSessionDeleteResult}
          onShippingInfoUpsert={this.handleSessionEnrolmentShippingUpsert}
          onDeleteEnrolment={this.handleSessionDeleteEnrolment}
          onSessionRelease={this.handleSessionEnrolmentsRelease}
          onCancelEnrolment={this.handleSessionCancelEnrolment}
          onSessionCharge={this.handleSessionCharge}
          onReplaceStudent={this.handleReplaceStudent}
        />
      </>
    );
  }

  /* private helper */
  async _execSessionOperation(op) {
    this.startLoading();
    const resp = await op;
    if (Array.isArray(resp) ? !resp.filter((a) => !a) : resp) await this.fetchData(RequestMask.SESSION);
    this.stopLoading();
  }
  _scrollToTop() {
    const element = document.querySelector('.scrollHere');
    if (element) scrollIntoView(element, { debug: false, time: 500, align: { top: 0 } });
  }
  /* private API calls */
  async _loadData(mask) {
    const resp = await Promise.all([
      (mask & RequestMask.SESSION) === RequestMask.SESSION
        ? this.props.app.classroom.session.getSession(this.state.sessionID, true)
        : Promise.resolve(),
      (mask & RequestMask.VENUES) === RequestMask.VENUES ? this.props.app.sharedCache().getVenues() : Promise.resolve(),
      (mask & RequestMask.INSTRUCTORS) === RequestMask.INSTRUCTORS
        ? this.props.app.sharedCache().getExpandedInstructors()
        : Promise.resolve(),
      (mask & RequestMask.CITIES) === RequestMask.CITIES ? this.props.app.sharedCache().getCities() : Promise.resolve(),
    ]);
    if (
      (mask & RequestMask.SESSION) === RequestMask.SESSION &&
      !(resp[0].statusCode == 200 && resp[0].body && resp[0].body.id)
    ) {
      this.props.app.alertController.showAPIErrorAlert(null, resp[0]);
      return false;
    }
    if ((mask & RequestMask.VENUES) === RequestMask.VENUES && !resp[1]) {
      this.props.app.alertController.showErrorAlert(null, 'Could not load venues!');
      return false;
    }
    if ((mask & RequestMask.INSTRUCTORS) === RequestMask.INSTRUCTORS && !resp[2]) {
      this.props.app.alertController.showErrorAlert(null, 'Could not load instructors!');
      return false;
    }
    if ((mask & RequestMask.CITIES) === RequestMask.CITIES && !resp[3]) {
      this.props.app.alertController.showErrorAlert(null, 'Could not load cities!');
      return false;
    }
    return {
      ...((mask & RequestMask.SESSION) === RequestMask.SESSION ? { session: resp[0].body } : {}),
      ...((mask & RequestMask.VENUES) === RequestMask.VENUES ? { venues: resp[1] } : {}),
      ...((mask & RequestMask.INSTRUCTORS) === RequestMask.INSTRUCTORS ? { instructors: resp[2] } : {}),
      courses: this.props.app.sharedCache().getAllUniqueCourses(),
    };
  }
  //Session operations
  async _deleteSession() {
    const resp = await this.props.app.classroom.session.deleteSession(this.state.sessionID);
    if (resp.statusCode == 200) {
      message.success('Session deleted with success!');
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return false;
    }
  }
  async _lockSession() {
    const resp = await this.props.app.classroom.session.lockSession(this.state.sessionID);
    if (resp.statusCode == 200) {
      message.success('Session locked with success!');
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return false;
    }
  }
  async _unlockSession(actionName) {
    const resp = await this.props.app.classroom.session.unlockSession(this.state.sessionID);
    if (resp.statusCode == 200) {
      message.success(`Session ${actionName} with success!`);
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return false;
    }
  }
  async _transitionSession(actionName) {
    const resp = await this.props.app.classroom.session.transitionSessionState(this.state.sessionID);
    if (resp.statusCode == 200) {
      message.success(`Session ${actionName} with success!`);
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return false;
    }
  }
  async _cancelSession() {
    const resp = await this.props.app.classroom.session.cancelSession(this.state.sessionID);
    if (resp.statusCode == 200) {
      message.success('Session cancelled with success!');
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return false;
    }
  }
  async _tooglePublishSession() {
    const toPublish = !this.state.session?.isEventPublished;
    const resp = await this.props.app.classroom.session.publishSession(this.state.sessionID, toPublish);
    if (resp.statusCode == 200) {
      message.success(`Session ${toPublish ? 'published' : 'unpublished'} with success!`);
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return false;
    }
  }
  async _releaseSession() {
    const resp = await this.props.app.classroom.session.releaseSession(this.state.sessionID);
    if (resp.statusCode == 200) {
      message.success(`Session access released for all students!`);
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return false;
    }
  }
  async _refreshSessionShippingInformation() {
    const resp = await this.props.app.classroom.session.refreshSessionShippingInformation(this.state.sessionID);
    if (resp.statusCode == 200) {
      message.success(`Refreshed Session Shipping Information!`);
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return false;
    }
  }

  //Instructor operations
  async _addInstructor(instructor, type) {
    const resp = await this.props.app.classroom.session.createSessionInstructor(
      this.state.sessionID,
      instructor.id,
      type
    );
    if (resp.statusCode == 200) {
      message.success(`Instructor added into session with success!`);
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return false;
    }
  }
  async _removeInstructor(userID) {
    const resp = await this.props.app.classroom.session.deleteSessionInstructor(this.state.sessionID, userID);
    if (resp.statusCode == 200) {
      message.success(`Instructor removed from session with success!`);
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return false;
    }
  }
  //Invitation operations
  async _batchInvite(invites) {
    const certSpec = this.props.app
      .sharedCache()
      .getTenantConfig()
      .certifications.find((c) => {
        if (c.requirementsIDs) return c.requirementsIDs.some((reqs) => reqs.some((r) => r == invites.courseID));
        else return false;
      });
    const resp = await this.props.app.api.certification.batchInvite(certSpec.id, invites);
    if (resp.statusCode == 200 && resp.body?.invites) {
      if (resp.body?.invites.some((i) => i.status != 200)) {
        const invitationFailures = resp.body.filter((item) => item.status != 200);
        const failedEmails = invitationFailures.map((item) => item.email).join('\n');
        this.sessionInvitation.invitationDrawer.form.setFieldsValue({ emails: failedEmails });

        message.error('Some of your invitations could not be sent');
        this.setState({ invitationFailures });
      } else {
        message.success(`Invitation${invites.length > 0 ? 's' : ''} successfully sent`);
        this.sessionInvitation.invitationDrawer.handleClose();
      }
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return false;
    }
  }
  async _removeInvitation(invitationID) {
    const resp = await this.props.app.classroom.sessionInvitation.deleteSessionInvitation(
      this.state.sessionID,
      invitationID
    );
    if (resp.statusCode == 200) {
      message.success(`Invitation removed from session with success!`);
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return false;
    }
  }
  //Enrolment operations
  async _enrolmentRelease(userID, externalID) {
    const resp = await this.props.app.classroom.sessionEnrolment.releaseSessionEnrolment(
      this.state.sessionID,
      userID,
      externalID
    );
    if (resp.statusCode == 200) {
      message.success(`Student access for the specified student released with success!`);
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return false;
    }
  }
  async _enrolmentLaunch(userID, externalID) {
    const resp = await this.props.app.classroom.sessionEnrolment.launchSessionEnrolment(
      this.state.sessionID,
      userID,
      externalID,
      { callbackURL: encodeURI(window.location) }
    );
    if (resp.statusCode == 200) {
      message.success(`Launching online provider for the specified student!`);
      this.props.app.urlManager.openExternalPage(resp.body.url);
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return false;
    }
  }
  async _enrolmentCheckGrade(userID, externalID) {
    const resp = await this.props.app.classroom.sessionEnrolment.checkSessionEnrolmentResults(
      this.state.sessionID,
      userID,
      externalID
    );
    if (resp.statusCode == 200) {
      // TODO: better handle this!
      message.success(
        `Student grade retrieved with success! Please, allow few seconds so the system can process the result.`
      );
      return true;
    } else if (resp.statusCode == 204) {
      message.warning(`Student grade not available yet!`);
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return false;
    }
  }
  async _enrolmentDelete(userID, externalID) {
    const resp = await this.props.app.classroom.sessionEnrolment.deleteSessionEnrolment(
      this.state.sessionID,
      userID,
      externalID
    );
    if (resp.statusCode == 200) {
      message.success(`Student removed from this session with success!`);
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return false;
    }
  }
  async _enrolmentCancel(userID, externalID) {
    const resp = await this.props.app.classroom.sessionEnrolment.cancelSessionEnrolment(
      this.state.sessionID,
      userID,
      externalID
    );
    if (resp.statusCode == 200) {
      message.success(`Student enrolment cancelled from this session with success!`);
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return false;
    }
  }
  //Attendance operations
  async _deleteAttendance(userID, externalID) {
    const resp = await this.props.app.classroom.sessionAttendance.deleteSessionAttendance(
      this.state.sessionID,
      userID,
      externalID
    );
    if (resp.statusCode == 200) {
      message.success(`Student attendance removed from this session with success!`);
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return false;
    }
  }
  async _deleteResult(userID, externalID) {
    const resp = await this.props.app.classroom.sessionResult.deleteSessionResult(
      this.state.sessionID,
      userID,
      externalID
    );
    if (resp.statusCode == 200) {
      message.success(`Student attendance removed from this session with success!`);
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return false;
    }
  }
  //Requires Instructor Confirmation
  _checkObligationWhenComplete(actionName) {
    return (
      this.state.session?.requiresInstructor &&
      (this.state.session?.state === Globals.Session_State.AVAILABLE ||
        this.state.session?.state == Globals.Session_State.PENDING_INSTRUCTOR)
    );
  }
  async _confirmRequiresInstructor() {
    return await this.props.app.alertController.showQuestionAlert(
      'Attention!',
      'This session requires an instructor review which was not done yet. Are you sure you want to set is as Completed?'
    );
  }
  //Organization operations
  async _getOrgData(orgID) {
    const resp = await this.props.app.organization.organizationApp.getOrganizationApp(orgID);
    if (resp.statusCode == 200 && resp.body) {
      return resp.body;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
  }
}
