import React from 'react';
import autoBind from 'react-autobind';
import { Table, Button } from 'antd';
//
import CustomComponent from '../../components/CustomComponent';
import Utils from '../../components/Utils';
import config from '@/config/config';
//
import '../../assets/stylesheets/AdminSearchUsersView.less';
//
export default class CommonActivationsTable extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { sortedInfo: { columnKey: 'createdOn', order: 'descend' } };
  }

  //Table support
  handleFilterChange(pagination, filters, sorter) {
    this.setState({ sortedInfo: sorter, filters });
  }

  productFilter(data, productFilter) {
    if (!data || data.length === 0 || !productFilter) return data;
    const filteredData = data.filter((item) => item.productID === productFilter.id);
    return filteredData;
  }

  getTableRowProps(record) {
    return {
      onClick: () => this.props.onRow && this.props.onRow(record),
    };
  }
  _handleOpenSession = (sessionID) => {
    const { app } = this.props;
    app.urlManager.openExternalPageWithParams(config.ApplicationRoutes.session, null, sessionID);
  };

  //UI
  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const columns = [
      {
        title: 'License ID',
        dataIndex: 'licenseID',
        key: 'licenseID',
        sortOrder: sortedInfo.columnKey === 'licenseID' && sortedInfo.order,
        sorter: (a, b) => a.licenseID.localeCompare(b.licenseID),
      },
      {
        title: 'Activation Key',
        dataIndex: 'activationKey',
        key: 'activationKey',
        sortOrder: sortedInfo.columnKey === 'activationKey' && sortedInfo.order,
        sorter: (a, b) => a.activationKey.localeCompare(b.activationKey),
      },
      {
        title: 'Product',
        key: 'productName',
        sortOrder: sortedInfo.columnKey === 'productName' && sortedInfo.order,
        sorter: (a, b) => a.productName.localeCompare(b.productName),
        render: (props) => (props?.productName ? props?.productName : '-'),
      },
      {
        title: 'Session',
        key: 'sessionID',
        sortOrder: sortedInfo.columnKey === 'sessionID' && sortedInfo.order,
        sorter: (a, b) => a.sessionID.localeCompare(b.sessionID),
        render: (props) => (
          <Button
            type="link"
            onClick={(e) => {
              e.stopPropagation();
              this._handleOpenSession(props.sessionID);
            }}
          >
            {props.sessionID}
          </Button>
        ),
      },
      {
        title: 'Student',
        dataIndex: 'name',
        key: 'name',
        sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
        sorter: (a, b) => a.name.localeCompare(b.name),
      },
      {
        title: 'Date',
        dataIndex: 'createdOn',
        key: 'createdOn',
        render: (createdOn) => (createdOn ? Utils.getDateOnUIFormatByTimestamp(createdOn) : ''),
        sorter: (a, b) => a.createdOn - b.createdOn,
        sortOrder: sortedInfo.columnKey === 'createdOn' && sortedInfo.order,
      },
    ];
    const filteredData = this.productFilter(this.props.consumptions, this.props.productFilter);

    const props = {
      loading: this.props.isLoading,
      onChange: this.handleFilterChange,
      locale: { emptyText: 'No Licenses found!' },
      pagination: { pageSize: 50, hideOnSinglePage: true, showSizeChanger: false, position: ['bottomCenter'] },
    };
    return <Table columns={columns} dataSource={filteredData} onRow={this.getTableRowProps} {...props} />;
  }
}
