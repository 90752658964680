import { Modal, Steps, message } from 'antd';
import React from 'react';
import autoBind from 'react-autobind';
import { Beforeunload } from 'react-beforeunload';
import { isMobile } from 'react-device-detect';
//
import CustomComponent from '../../../../components/CustomComponent';
//
import Utils from '../../../../components/Utils';
import Globals from '../../../../config/Globals';
//
import CommonProductPurchaseModalStep_LoadingView from './CommonProductPurchaseModal_steps/CommonProductPurchaseModalStep_LoadingView';
import CommonProductPurchaseModalStep_OverviewView from './CommonProductPurchaseModal_steps/CommonProductPurchaseModalStep_OverviewView';
import CommonProductPurchaseModalStep_PaymentView_Braintree from './CommonProductPurchaseModal_steps/CommonProductPurchaseModalStep_PaymentView_Braintree';
import CommonProductPurchaseModalStep_PaymentView_Federated, {
  PAYMENT_TYPE,
} from './CommonProductPurchaseModal_steps/CommonProductPurchaseModalStep_PaymentView_Federated';
import CommonProductPurchaseModalStep_PaymentView_Moneris from './CommonProductPurchaseModal_steps/CommonProductPurchaseModalStep_PaymentView_Moneris';
import CommonProductPurchaseModalStep_PaymentView_Stripe from './CommonProductPurchaseModal_steps/CommonProductPurchaseModalStep_PaymentView_Stripe';
import CommonProductPurchaseModalStep_ResultView from './CommonProductPurchaseModal_steps/CommonProductPurchaseModalStep_ResultView';
import CommonProductPurchaseModalStep_SummaryView_Braintree from './CommonProductPurchaseModal_steps/CommonProductPurchaseModalStep_SummaryView_Braintree';
import CommonProductPurchaseModalStep_SummaryView_Federated from './CommonProductPurchaseModal_steps/CommonProductPurchaseModalStep_SummaryView_Federated';
import CommonProductPurchaseModalStep_SummaryView_Moneris from './CommonProductPurchaseModal_steps/CommonProductPurchaseModalStep_SummaryView_Moneris';
import CommonProductPurchaseModalStep_SummaryView_Stripe from './CommonProductPurchaseModal_steps/CommonProductPurchaseModalStep_SummaryView_Stripe';
import CommonProductPurchaseModalStep_InfoConfirmation from './CommonProductPurchaseModal_steps/CommonProductPurchaseModalStep_InfoConfirmation';
//
import '../../../../assets/stylesheets/CommonLicensePurchaseModal.less';
//
export const STEPS = {
  OVERVIEW: 'OVERVIEW',
  SENSITIVE: 'SENSITIVE',
  SUMMARY: 'SUMMARY',
  SENDING_ORDER: 'SENDING_ORDER',
  CONFIRMING_ORDER: 'CONFIRMING_ORDER',
  RESULT: 'RESULT',
  INFO_CONFIRMATION: 'INFO_CONFIRMATION',
};

//State management
const INITIAL_STATE = ({ redirectOrderID, redirectPurchaseID, redirectProductID, redirectCustomID }, primaryStep) => {
  const isRedirecting = !!(redirectOrderID && redirectPurchaseID && redirectProductID);
  return {
    isLoading: false,
    overrideValue: null,
    hasInfoConfirmationStep: true,
    stepper: {
      //stepper possible statuses - waiting, process, finish, error
      current: isRedirecting ? STEPS.SENDING_ORDER : primaryStep,
      status: isRedirecting ? 'process' : 'waiting',
    },
    optExplicitProductObject: null,
    //Main product stuff
    selectedObjectIndex: 0,
    selectedProduct: null,
    products: null,
    chargingAmount: 0, //for one unit of the selected product
    shippingAmount: 0,
    taxAmount: 0,
    totalAmount: 0,
    quantity: 0,
    discountAmount: 0,
    //Voucher support
    applingVoucher: false,
    validatedVoucher: null,
    selectedReferral: { id: null, name: null },
    // Custom material shipping info
    materialShippingInfo: null,
    //Purchase info
    payload: {
      /* here we store information coming from begin order, and also from redi*/ error: null,
      /* redirect support - federated */ redirectOrderID,
      redirectPurchaseID,
      redirectProductID,
      redirectCustomID,
      isRedirecting,
      /* federated secure form */ gatewayUrl: null,
      /* moneris support */ providerLib: null,
      providerEnv: null,
      ticketID: null,
    },
    //Meta
    sessionID: null,
  };
};
const RESET_STATE = (previous, primaryStep) => {
  return {
    isLoading: false,
    stepper: { current: primaryStep, status: 'waiting' },
    overrideValue: previous.overrideValue,
    optExplicitProductObject: null,
    //Main product stuff
    selectedObjectIndex: 0,
    selectedProduct: previous.selectedProduct,
    chargingAmount: 0, //for one unit of the selected product
    taxAmount: 0,
    totalAmount: 0,
    quantity: previous.quantity,
    discountAmount: 0,
    //Purchase info
    payload: {
      /* here we store sensitive information ie sessionID: previous?.payload?.sessionID */
    },
    //Meta
    sessionID: previous?.sessionID,
  };
};

export default class CommonProductPurchaseModal extends CustomComponent {
  /**
   * Creates an instance of CommonProductPurchaseModal. (accepts second param so subclasses can specify primary step!)
   *
   * @constructor
   * @param { { app, onRequiresAttention, isVisible, fixedQuantity?, quantity?, user?, org? } } props
   * @param { string } optionalCustomPrimaryStep
   */
  constructor(props, optionalCustomPrimaryStep) {
    super(props);
    autoBind(this);
    this.calculateDebounce = null;
    this.calculatePromisesCallbacks = [];
    this.isSubmittingSecureForm = false; //needs to be outside state because we don't want to update state when setting and checking it
    this.primaryStep =
      optionalCustomPrimaryStep && optionalCustomPrimaryStep.length > 0 ? optionalCustomPrimaryStep : STEPS.OVERVIEW;
    const redirectOrderID = this.props.app.idm.urlmanager.getParam(Globals.URL_Path_LicensePurchase_OrderID);
    const redirectPurchaseID = this.props.app.idm.urlmanager.getParam(Globals.URL_Path_LicensePurchase_TokenID);
    const redirectProductID = this.props.app.idm.urlmanager.getParam(Globals.URL_Path_LicensePurchase_ProductID);
    const redirectCustomID = this.props.app.idm.urlmanager.getParam(Globals.URL_Path_LicensePurchase_CustomID);
    this.state = INITIAL_STATE(
      { redirectOrderID, redirectPurchaseID, redirectProductID, redirectCustomID },
      this.primaryStep
    );
  }
  //Life cycle
  componentDidMount() {
    //check if is on redirect, if so, ask parent for attention (display modal with loaded data)
    if (this.state.payload.isRedirecting && this.props.onRequiresAttention) this.props.onRequiresAttention(this);
  }

  /* Public */
  /**
   * Load modal information
   *
   * @param { string } productID
   * @param { number | undefined } overrideValue
   * @param {*} optExplicitProductObject
   */
  async loadModalInfo(productID, overrideValue, optExplicitProductObject) {
    //Get all interesting ivars
    const isRedirecting = this.state.payload.isRedirecting;
    const selectedObjectIndex = 0;
    //If coming from redirect, grab product from URL and find product object
    let products = [];
    if (!productID && this.state.payload.redirectProductID) {
      products = [this.props.app.sharedCache().getProductByID(this.state.payload.redirectProductID)];
    }
    //Get products that aren't licenses
    products = await this.props.app.sharedCache().loadProducts();
    if (products.statusCode != 200) {
      this.props.app.alertController.showAPIErrorAlert(null, products);
    }
    if (productID) products = products.body?.products.filter((p) => p.id == productID);
    else products = products.body?.products.filter((p) => !p.hideInPurchaseDialog && p.allowInProductOrder);
    //Collect info
    const productSpecs = products[selectedObjectIndex];
    const quantity = this.props.quantity || 1;
    const originalAmount = overrideValue
      ? overrideValue * quantity
      : this._getProductPriceByQuantity(quantity, productSpecs);
    const hasInfoConfirmationStep = productSpecs.requiresShipping;
    this.setState(
      {
        // chargingAmount: this.props.app.isAdmin() ? 0 : originalAmount, TODO: not doing this anymore?
        chargingAmount: originalAmount,
        quantity,
        taxAmount: 0,
        totalAmount: 0,
        discountAmount: 0,
        selectedObjectIndex,
        products,
        selectedProduct: productSpecs,
        isLoading: false,
        overrideValue,
        optExplicitProductObject,
        hasInfoConfirmationStep,
      },
      () => {
        if (isRedirecting) this._completeOrder();
        else {
          //org managers may have hidden vouchers :)
          if (this._hasHiddenVoucher() && !this.props.app.isAdmin()) this._validateVoucher(this._getHiddenVoucher());
          else this.handleOverviewChange(null); //refreshed first step with just set information
        }
      }
    );
  }

  /* Protected interface */
  closeModal() {
    this.handleCancel();
  }
  getCurrentStep() {
    return this.state.stepper.current;
  }
  setSessionID(sessionID) {
    this.setState({ sessionID });
  }

  //Actions
  /**
   * Handle changes on overview step
   *
   * @async
   * @param { number } value
   * @param { string } id
   */
  async handleOverviewChange(value, id) {
    let { chargingAmount, selectedObjectIndex, quantity, products, shippingAmount } = this.state;
    let product = products[selectedObjectIndex];
    //
    this.setState({ stepper: { ...this.state.stepper, status: 'process', error: null }, isLoading: true });
    if (id == 'selectedReferral') {
      this.setState({ selectedReferral: value });
    }
    //Check for the amount to be set
    if (id == 'object') {
      selectedObjectIndex = value;
      product = products[selectedObjectIndex];
      // chargingAmount = this.props.app.isAdmin() ? 0 : this._getProductPriceByQuantity(quantity || 1, product);
      chargingAmount = this._getProductPriceByQuantity(quantity || 1, product);
      shippingAmount = product.shippingCharges || 0;
    } else if (id == 'quantity') {
      quantity = value;
      //Not a normal user (is org) and not admin
      if (!this._isNormalUser() && !this.props.app.isAdmin())
        chargingAmount = this._getProductPriceByQuantity(value || 0);
    } else if (id == 'amount') chargingAmount = value;
    else if (id == 'shippingAmount') shippingAmount = value;

    //Get server side calculate values
    let serverValues = {};
    if (quantity != undefined) {
      serverValues = await this._scheduleCalculateOrderValues({
        selectedProduct: product,
        chargingAmount,
        shippingAmount,
        quantity,
      });
      if (!serverValues) return;
    }
    //
    this.setState({
      chargingAmount: serverValues.itemsValueCharging || 0,
      selectedObjectIndex,
      quantity: serverValues.items[0].quantity,
      taxAmount: serverValues.orderValueTax || 0,
      totalAmount: serverValues.orderValueTotal || 0,
      discountAmount: serverValues.itemsValueDiscount || 0,
      shippingAmount: serverValues.shippingChargesValue || 0,
      selectedProduct: product,
      isLoading: false,
      hasInfoConfirmationStep: product.requiresShipping,
      stepper: { ...this.state.stepper, status: 'waiting', error: null },
    });
  }
  /**
   * Handle primary actions on each step
   *
   * @async
   * @param { { paymentMethod: string, materialShippingInfo: object } } data
   */
  async handleStepPrimaryAction(data) {
    //Overview
    if (this.state.stepper.current == STEPS.OVERVIEW) {
      const resp = await this.currentChild.validateFields(); //validate form
      if (resp)
        this.setState({
          stepper: {
            current: this.state.hasInfoConfirmationStep ? STEPS.INFO_CONFIRMATION : STEPS.SENSITIVE,
            status: 'waiting',
          },
        }); //just present sentitive
    }
    // Info confirmation
    else if (this.state.stepper.current == STEPS.INFO_CONFIRMATION) {
      this.setState({
        materialShippingInfo: data.materialShippingInfo,
      });
      if (this.state.totalAmount <= 0) {
        //check if is waiving, skip to begin order, no payment method needed
        if (this.props.app.isOrgManager())
          this._submitInvoiceOrder(this._getCurrentUser()); //Org managers should do invoice, even with 0 value orders
        else this._beginOrder(this._getCurrentUser()); //other users are OK. (actually this should only be possible for admins or 100% vouchers)
      } else {
        this.setState({
          stepper: { ...this.state.stepper, current: STEPS.SENSITIVE, status: 'waiting', error: null },
        });
      }
    }
    //Payment info
    else if (this.state.stepper.current == STEPS.SENSITIVE) {
      const resp = await this.currentChild.validateFields();
      if (resp && resp.paymentMethod == PAYMENT_TYPE.INVOICE) {
        //Check if is invoice, dont call begin order, skip to summary
        this.setState(
          {
            stepper: { ...this.state.stepper, current: STEPS.SUMMARY, status: 'waiting', error: null },
            payload: { ...this.state.payload },
            isLoading: false,
          },
          () => {
            this.currentChild.setSensitiveInfo({ ...resp });
          }
        );
      } else if (resp) {
        this._beginOrder(resp);
      } //CC payment
    }
    //Summary
    else if (this.state.stepper.current == STEPS.SUMMARY) {
      //check for payment method
      if (data.paymentMethod == PAYMENT_TYPE.CARD) {
        this.isSubmittingSecureForm = true; //CC
        if (this.props.app.license.productOrder.isMonerisEnabledForProduct(this.state.selectedProduct))
          this._completeOrder();
        else if (this.props.app.license.productOrder.isBraintreeEnabledForProduct(this.state.selectedProduct))
          this._completeOrder();
        else if (this.props.app.license.productOrder.isStripeEnabledForProduct(this.state.selectedProduct))
          this._completeOrder(data);
      } else {
        const org = await this.props.app.organization.organizationApp.getOrganizationApp(
          this.props.app.urlManager.selectedOrg
        );
        if (org.statusCode != 200 || !org.body) {
          this.props.app.alertController.showAPIErrorAlert(null, org);
        }
        this._submitInvoiceOrder(this._getCurrentUser(true, org?.body)); //Invoice
      }
    }
    //Result
    else if (this.state.stepper.current == STEPS.RESULT) {
      this.handleCancel();
      // Leaving this here as legacy code, we were skipping the
      // resulting error and resetting the modal, throwing the user
      // back to the primary/overview step, now we want the user back
      // to the course card so we can check possible lic tickets on EX
      // and direct the user to the correct modal
      // //errored, reset
      // if (this.state.payload.error) {
      //   this.setState(RESET_STATE(this.state, this.primaryStep), () => {
      //     this.isSubmittingSecureForm = false;
      //     const selectedObject = this.state.productObjects[this.state.selectedObjectIndex];
      //     const productID = (selectedObject ? this.props.app.sharedCache().getProductByProductRelatedObject(selectedObject).id : null);
      //     this.loadModalInfo(productID, null, selectedObject);
      //   });
      // }
      // else { this.handleCancel(); } //close
    }
  }
  //Handle previous into steps
  handlePreviousAction(data) {
    if (this.state.stepper.current == STEPS.SUMMARY) {
      this.setState({ stepper: { current: STEPS.SENSITIVE, status: 'waiting' } }, () => {
        if (this.state.payload.redirectOrderID) this._cancelOrder(false);
        this.currentChild.setInfo(data); //sensitive (federated and moneris)
      });
    } else if (this.state.stepper.current == STEPS.SENSITIVE) {
      this.setState({ stepper: { current: STEPS.OVERVIEW, status: 'waiting' } }, () => {
        if (this.state.payload.redirectOrderID) this._cancelOrder(false); //moneris or any other
      });
    } else if (this.state.stepper.current == STEPS.OVERVIEW && STEPS.OVERVIEW != this.primaryStep) {
      this.setState({ stepper: { current: this.primaryStep, status: 'waiting' } }, () => {
        if (this.state.payload.redirectOrderID) this._cancelOrder(false); //moneris or any other
      });
    } else if (this.state.stepper.current == STEPS.INFO_CONFIRMATION) {
      this.setState({ stepper: { current: this.primaryStep, status: 'waiting' } }, () => {
        if (this.state.payload.redirectOrderID) this._cancelOrder(false);
      });
    }
  }
  //Handle cancel actions on each step (or programatically)
  handleCancel() {
    if (this.currentChild && this.currentChild.form) this.currentChild.form.resetFields();
    //Make cancellation request if on second step and redirectPurchaseID is set!
    if (this.state.payload.redirectOrderID && this.state.stepper.current != STEPS.RESULT) this._cancelOrder(true);
    else this.props.onChange();
  }
  //Handle modal after close, reset modal for next usage
  handleAfterClose() {
    this.setState(INITIAL_STATE({}, this.primaryStep));
    this.isSubmittingSecureForm = false;
  }
  //Handler voucher create
  handleVoucherApply(voucherCode) {
    this._validateVoucher(voucherCode);
  }

  //UI
  render() {
    return (
      <Modal
        maskClosable={false}
        title={this._getTitle()}
        afterClose={this.handleAfterClose.bind(this)}
        open={this.props.isVisible}
        confirmLoading={this.state.isLoading || this.state.applingVoucher}
        closable={false}
        footer={null}
      >
        {this.props.isVisible && (
          <Beforeunload
            onBeforeunload={() => {
              /* handler seems to keep installed if not rendered after rendering */
              if (!this.props.isVisible || this.isSubmittingSecureForm) return false; //allow exit
              return 'Your order will be cancelled, are you sure you want to cancel it?';
            }}
          />
        )}
        <Steps
          {...this.state.stepper}
          current={this._getStepIndex()}
          size="small"
          direction={isMobile ? 'vertical' : 'horizontal'}
          className="paymentSteps"
        >
          {this._renderStepperSteps()}
        </Steps>
        {this._renderSelectedStep()}
      </Modal>
    );
  }

  /* protected - this is what should be overwritten when extending this modal */
  _getStepIndex() {
    const { hasInfoConfirmationStep } = this.state;
    const increaseStepIdx = hasInfoConfirmationStep ? 1 : 0;

    if (this.state.stepper.current == STEPS.OVERVIEW) return 0;
    if (hasInfoConfirmationStep && this.state.stepper.current == STEPS.INFO_CONFIRMATION) return 1;
    if (this.state.stepper.current == STEPS.SENSITIVE) return 1 + increaseStepIdx;
    if (this.state.stepper.current == STEPS.SUMMARY) return 2 + increaseStepIdx;
    if (this.state.stepper.current == STEPS.SENDING_ORDER) return 3 + increaseStepIdx;
    if (this.state.stepper.current == STEPS.CONFIRMING_ORDER) return 4 + increaseStepIdx;
    if (this.state.stepper.current == STEPS.RESULT) return 5 + increaseStepIdx;
  }
  _renderStepperSteps() {
    const { hasInfoConfirmationStep } = this.state;

    return (
      <>
        <Steps.Step title="Shipping Info" />
        {hasInfoConfirmationStep && <Steps.Step title="Shipping Info" />}
        <Steps.Step
          title={
            this.state.selectedProduct &&
            (this.props.app.license.productOrder.isMonerisEnabledForProduct(this.state.selectedProduct) ||
              this.props.app.license.productOrder.isBraintreeEnabledForProduct(this.state.selectedProduct))
              ? 'Payment Type'
              : 'Billing Info'
          }
        />
        <Steps.Step title="Summary" />
        <Steps.Step title="Processing" />
        <Steps.Step title="Confirming" />
        <Steps.Step title={this.state.stepper.error ? 'Failed' : 'Completed'} />
      </>
    );
  }
  _renderSelectedStep() {
    if (this.state.stepper.current == STEPS.OVERVIEW) return this._renderStepOverview();
    if (this.state.stepper.current == STEPS.INFO_CONFIRMATION) return this._renderStepInfoConfirmation();
    else if (this.state.stepper.current == STEPS.SENSITIVE) return this._renderStepPayment();
    else if (this.state.stepper.current == STEPS.SUMMARY) return this._renderStepSummary();
    else if (this.state.stepper.current == STEPS.SENDING_ORDER) return this._renderStepLoading(0);
    else if (this.state.stepper.current == STEPS.CONFIRMING_ORDER) return this._renderStepLoading(1);
    else if (this.state.stepper.current == STEPS.RESULT) return this._renderStepResult();
  }

  /* private render */
  _renderStepInfoConfirmation() {
    return (
      <CommonProductPurchaseModalStep_InfoConfirmation
        app={this.props.app}
        onNext={this.handleStepPrimaryAction.bind(this)}
        onCancel={this.handleCancel.bind(this)}
        onPrevious={this.handlePreviousAction.bind(this)}
        user={this._getCurrentUser()}
      />
    );
  }

  _renderStepOverview() {
    return (
      <CommonProductPurchaseModalStep_OverviewView
        product={this.state.selectedProduct}
        products={this.state.products}
        app={this.props.app}
        isLoading={this.state.isLoading}
        selectedObjectIndex={this.state.selectedObjectIndex}
        quantity={this.state.quantity}
        taxAmount={this.state.taxAmount}
        totalAmount={this.state.totalAmount}
        discountAmount={this.state.discountAmount}
        chargingAmount={this.state.chargingAmount}
        shippingAmount={this.state.shippingAmount}
        fixedQuantity={this.props.fixedQuantity}
        onPrevious={this.handlePreviousAction.bind(this)}
        hasPrevious={this.primaryStep != STEPS.OVERVIEW}
        onChange={this.handleOverviewChange.bind(this)}
        onCancel={this.handleCancel.bind(this)}
        onNext={this.handleStepPrimaryAction.bind(this)}
        onVoucherApply={this.handleVoucherApply.bind(this)}
        selectedReferral={this.state.selectedReferral}
        applingVoucher={this.state.applingVoucher}
        validatedVoucher={this.state.validatedVoucher}
        isHiddenVoucher={this._hasHiddenVoucher()}
        {...Utils.propagateRef(this, 'currentChild')}
      />
    );
  }
  _renderStepPayment() {
    let Class = null;
    if (this.props.app.license.productOrder.isStripeEnabledForProduct(this.state.selectedProduct))
      Class = CommonProductPurchaseModalStep_PaymentView_Stripe;
    else if (this.props.app.license.productOrder.isMonerisEnabledForProduct(this.state.selectedProduct))
      Class = CommonProductPurchaseModalStep_PaymentView_Moneris;
    else if (this.props.app.license.productOrder.isBraintreeEnabledForProduct(this.state.selectedProduct))
      Class = CommonProductPurchaseModalStep_PaymentView_Braintree;
    else Class = CommonProductPurchaseModalStep_PaymentView_Federated;
    return (
      <Class
        app={this.props.app}
        onPrevious={this.handlePreviousAction.bind(this)}
        onCancel={this.handleCancel.bind(this)}
        onNext={this.handleStepPrimaryAction.bind(this)}
        user={this._getCurrentUser()}
        totalAmount={this.state.totalAmount}
        isNormalUser={this._isNormalUser()}
        product={this.state.selectedProduct}
        {...Utils.propagateRef(this, 'currentChild')}
        isLoading={this.state.isLoading}
      />
    );
  }
  _renderStepSummary() {
    let Class = null;
    if (this.props.app.license.productOrder.isStripeEnabledForProduct(this.state.selectedProduct))
      Class = CommonProductPurchaseModalStep_SummaryView_Stripe;
    else if (this.props.app.license.productOrder.isMonerisEnabledForProduct(this.state.selectedProduct))
      Class = CommonProductPurchaseModalStep_SummaryView_Moneris;
    else if (this.props.app.license.productOrder.isBraintreeEnabledForProduct(this.state.selectedProduct))
      Class = CommonProductPurchaseModalStep_SummaryView_Braintree;
    else Class = CommonProductPurchaseModalStep_SummaryView_Federated;
    return (
      <Class
        onCancel={this.handleCancel.bind(this)}
        onPrevious={this.handlePreviousAction.bind(this)}
        onNext={this.handleStepPrimaryAction.bind(this)}
        {...Utils.propagateRef(this, 'currentChild')}
        app={this.props.app}
        isLoading={this.state.isLoading || this.state.applingVoucher}
        payload={this.state.payload}
        product={this.state.selectedProduct}
        quantity={this.state.quantity}
        taxAmount={this.state.taxAmount}
        totalAmount={this.state.totalAmount}
        chargingAmount={this.state.chargingAmount}
        user={this._getCurrentUser()}
        discountAmount={this.state.discountAmount}
      />
    );
  }
  _renderStepLoading(type) {
    return <CommonProductPurchaseModalStep_LoadingView loadingType={type} />;
  }
  _renderStepResult() {
    return (
      <CommonProductPurchaseModalStep_ResultView
        error={this.state.payload.error}
        isInvoice={this.state.payload.isInvoice}
        onNext={this.handleStepPrimaryAction.bind(this)}
        onCancel={this.handleCancel.bind(this)}
        isLoading={this.state.isLoading || this.state.applingVoucher}
        app={this.props.app}
      />
    );
  }

  /* multi user support */
  _getCurrentUser(isOrgMngr, org) {
    //props needed: id, firstName, lastName, email
    if (this.props.user && !this.props.user?.orgCharge && !isOrgMngr) return this.props.user;
    else if (this.props.org) {
      let usr = {};
      if (this.props.user?.orgCharge) usr = this.props.user;
      else usr = this.props.app.sharedCache().getProgramUser();
      return {
        id: this.props.org.orgID,
        userID: usr.id,
        firstName: this.props.org.name,
        lastName: `${usr ? `- ${usr.firstName} ` : ''}${usr ? `${usr.lastName} ` : ''}${usr ? `${usr.email} ` : ''}`,
        email: this.props.org.managers ? this.props.org.managers.map((m) => m.email) : [],
      };
    } else if (isOrgMngr && org) {
      const usr = this.props.app.sharedCache().getProgramUser();
      return {
        id: org.orgID,
        firstName: org.name,
        lastName: `${usr ? `- ${usr.firstName} ` : ''}${usr ? usr.lastName : ''}`,
        email: org.managers ? org.managers.map((m) => m.email) : [],
      };
    } else return this.props.app.sharedCache().getProgramUser();
  }
  _isNormalUser() {
    return !!this.props.user;
  }
  _getHiddenVoucher() {
    //{valid discPercent discAmount}
    if (this.props.org && this.props.org.metadata) return this.props.org.metadata?.voucherID;
    return null;
  }
  _hasHiddenVoucher() {
    if (this.props.org && this.props.org.metadata && this.props.org.metadata?.voucherID)
      return this.props.org.metadata?.voucherID?.length > 0;
    return false;
  }
  _getProductPriceByQuantity(quantity, optionalProduct) {
    let product = optionalProduct || this.state.products[this.state.selectedObjectIndex];
    if (product.bulkPrice && !this._isNormalUser()) {
      //Reorder just to make sure
      product.bulkPrice = product.bulkPrice.sort((a, b) => a.numLicenses - b.numLicenses);
      //Match first
      for (let priceSchema of product.bulkPrice) {
        if (priceSchema.numLicenses >= quantity) return priceSchema.discountedPrice;
      }
    }
    return (this.state.overrideValue || product.price) * quantity;
  }
  _getTitle() {
    //TODO: THIS WAS RETRIEVED FROM THE CERTIFICATION/COURSE/APPLICATION OBJECT NEED TO UPDATE
    const object = this.state.products?.[this.state.selectedObjectIndex];
    return object?.displayName || object?.description || object?.name || '';
  }

  /* API + transitions */
  async _beginOrder(data) {
    this.setState({ stepper: { ...this.state.stepper, status: 'process', error: null }, isLoading: true });
    //Generate request
    const user = this._getCurrentUser();
    const provider = this.props.app.license.productOrder.getProviderFromProduct(this.state.selectedProduct);
    const shippingCharges = this.state.shippingAmount || this.state.selectedProduct?.shippingCharges || 0;
    const beginOrderObj = {
      items: [
        {
          // customID: this.state.optExplicitProductObject?.id, //course ID used on federated payment calls - NO SUPPORT YET
          // externalProductID: this.state.optExplicitProductObject?.id, //course ID used on affiliate payment object - NO SUPPORT YET
          //
          productID: this.state.selectedProduct?.id,
          quantity: this.state.quantity,
          unitValue: this.state.chargingAmount,
          taxPercentage: this._getTaxPercentage(),
          shouldUseBulkPricing: !this._isNormalUser() && !this.props.app.isAdmin(),
          shippingCharges,
        },
      ],
      shippingInfo: this.state.materialShippingInfo,
      user: {
        id: user.id,
        firstName: data.firstName,
        lastName: data.lastName,
        email: user.email,
        referrerName: user.referredByName,
      },
      voucherID: this.state.validatedVoucher ? this.state.validatedVoucher.voucherID : null,
      callbackURL: window.location.href,
      sessionID: this.state.sessionID,
    };
    //Make request
    const paymentResp = await this.props.app.license.productOrder.beginOrder(beginOrderObj, provider);
    if (!this._isMounted) return;
    //Response
    console.debug('Order begin resp:', paymentResp);
    if (paymentResp.statusCode == 200 && paymentResp.body && paymentResp.body.orderID) {
      //Check if want to skip payment - disallow org manager grant
      if (this.state.totalAmount === 0 && !this.props.app.isOrgManager()) {
        this.setState(
          {
            stepper: { ...this.state.stepper, current: STEPS.SENDING_ORDER, status: 'waiting', error: null },
            payload: { ...this.state.payload, redirectOrderID: paymentResp.body.orderID },
            isLoading: false,
          },
          () => {
            message.info('Skipping Payment!');
            this._completeOrder();
          }
        );
      } else {
        this.setState(
          {
            stepper: { ...this.state.stepper, current: STEPS.SUMMARY, status: 'waiting', error: null },
            payload: {
              ...this.state.payload,
              gatewayUrl: paymentResp.body.url,
              redirectOrderID: paymentResp.body.orderID,
              ...paymentResp.body /* moneris params */,
            },
            isLoading: false,
          },
          () => {
            this.currentChild.setSensitiveInfo(data);
          }
        );
      }
    } else {
      this.setState({
        stepper: { ...this.state.stepper, current: STEPS.RESULT, status: 'error' },
        isLoading: false,
        payload: { ...this.state.payload, error: paymentResp.body || 'Unknown error!' },
      });
      this.props.app.alertController.showAPIErrorAlert(null, paymentResp);
    }
  }
  async _completeOrder(data) {
    let paymentResp = null;
    const provider = this.props.app.license.productOrder.getProviderFromProduct(this.state.selectedProduct);
    //Clean URL
    this.props.app.urlManager.updateQueryStringParam(Globals.URL_Path_LicensePurchase_OrderID, null);
    this.props.app.urlManager.updateQueryStringParam(Globals.URL_Path_LicensePurchase_TokenID, null);
    this.props.app.urlManager.updateQueryStringParam(Globals.URL_Path_LicensePurchase_ProductID, null);
    this.props.app.urlManager.updateQueryStringParam(Globals.URL_Path_LicensePurchase_SessionID, null);
    //Start loading
    this.setState({ stepper: { ...this.state.stepper, status: 'process', error: null }, isLoading: true });
    //Make request
    const user = this._getCurrentUser();
    paymentResp = await this.props.app.license.productOrder.completeOrder(
      {
        orderID: this.state.payload.redirectOrderID + '',
        confirmationID: this.state.payload.redirectPurchaseID || this.state.payload.ticketID,
        paymentMethod: data ? data.paymentMethodID : null,
        user: { id: user.id },
      },
      provider
    );
    if (!this._isMounted) return;
    //Response
    console.debug('Order completion resp: ', paymentResp);

    if (paymentResp.statusCode == 200 && paymentResp.body) {
      message.success('Order Completed!');
      this.setState({
        stepper: { ...this.state.stepper, current: STEPS.RESULT, status: 'finish', error: null },
        isLoading: false,
        payload: {
          /* reset */
        },
      });
    } else {
      this.setState({
        stepper: { ...this.state.stepper, current: STEPS.RESULT, status: 'error' },
        isLoading: false,
        payload: { ...this.state.payload, error: paymentResp.body || 'Unknown error!' },
      });
      this.props.app.alertController.showAPIErrorAlert(null, paymentResp);
    }
  }
  async _cancelOrder(modifyStep) {
    //Start loading
    this.setState({ stepper: { ...this.state.stepper, status: 'process', error: null }, isLoading: true });
    //Make request
    const user = this._getCurrentUser();
    const provider = this.props.app.license.productOrder.getProviderFromProduct(this.state.selectedProduct);
    const paymentResp = await this.props.app.license.productOrder.cancelOrder(
      {
        orderID: this.state.payload.redirectOrderID + '',
        user: { id: user.id },
      },
      provider
    );
    if (!this._isMounted) return;
    //Response
    console.debug('Order cancellation resp: ', paymentResp);
    if (paymentResp.statusCode == 200 && paymentResp.body) {
      if (modifyStep) {
        message.warning('Order Cancelled!');
        this.props.onChange(); //ask to hide modal
      } else {
        this.setState({
          payload: { ...this.state.payload, redirectOrderID: null, redirectPurchaseID: null, redirectProductID: null },
          stepper: { ...this.state.stepper, status: 'waiting', error: null },
          isLoading: false,
        });
      }
    } else {
      // might not be able to cancel order if it's already processed (eg: declined)
      if (modifyStep) {
        this.props.onChange(); //ask to hide modal
      } else {
        //reset
        this.setState({
          payload: { ...this.state.payload, redirectOrderID: null, redirectPurchaseID: null, redirectProductID: null },
          stepper: { ...this.state.stepper, status: 'waiting', error: null },
          isLoading: false,
        });
      }
      // Leaving this here as legacy code, we don't want to show an error alert if the order is already processed
      // if (modifyStep)
      //   this.setState({
      //     stepper: { ...this.state.stepper, current: STEPS.RESULT, status: 'error' },
      //     isLoading: false,
      //     payload: { ...this.state.payload, error: paymentResp.body || 'Unknown error!' },
      //   });
      // this.props.app.alertController.showAPIErrorAlert(null, paymentResp);
    }
  }
  //THIS ISN'T AVAILABLE YET
  async _submitInvoiceOrder(data) {
    //Start loading
    this.setState({
      stepper: { ...this.state.stepper, current: STEPS.SENDING_ORDER, status: 'process', error: null },
      isLoading: true,
    });
    //Make request
    const paymentResp = await this.props.app.license.productOrder.submitInvoiceOrder({
      productID: this.state.selectedProduct.id,
      user: { id: data.id, name: `${data.firstName} ${data.lastName}`, email: data.email },
      taxPercentage: this._getTaxPercentage(),
      appURL: this.props.app.urlManager.appURL,
      quantity: this.state.quantity,
      unitValue: this.state.chargingAmount,
      shouldUseBulkPricing: !this._isNormalUser() && !this.props.app.isAdmin(),
      voucherID: this.state.validatedVoucher ? this.state.validatedVoucher.voucherID : null,
    });
    if (!this._isMounted) return;
    //Response
    console.debug('Invoice submission resp: ', paymentResp);
    if (paymentResp.statusCode == 200 && paymentResp.body) {
      message.success('Invoice Submitted!');
      this.setState({
        stepper: { ...this.state.stepper, current: STEPS.RESULT, status: 'finish', error: null },
        isLoading: false,
        payload: { isInvoice: true /* reset */ },
      });
    } else {
      this.setState({
        stepper: { ...this.state.stepper, current: STEPS.RESULT, status: 'error' },
        isLoading: false,
        payload: { ...this.state.payload, error: paymentResp.body || 'Unknown error!' },
      });
      this.props.app.alertController.showAPIErrorAlert(null, paymentResp);
    }
  }
  async _validateVoucher(voucherID) {
    //Start loading
    this.setState({ applingVoucher: true });
    //Make request
    const verifyResp = await this.props.app.license.voucher.verifyVoucher(encodeURIComponent(voucherID));
    if (!this._isMounted) return;
    //Response
    console.debug('Voucher verify resp: ', verifyResp);
    if (verifyResp.statusCode == 200 && verifyResp.body) {
      if (verifyResp.body.valid == true) {
        if (!this._hasHiddenVoucher())
          message.success(
            `Voucher validated with discount of ${
              verifyResp.body.discPercent ? `${verifyResp.body.discPercent * 100}%` : `$${verifyResp.body.discAmount}`
            }!`
          );
        this.setState({ applingVoucher: false, validatedVoucher: { ...verifyResp.body, voucherID } }, () => {
          this.handleOverviewChange(null); //reload data
        });
      } else {
        message.error(`Voucher is not valid!`);
        this.setState({ applingVoucher: false, validatedVoucher: null }, () => {
          this.handleOverviewChange(null); //reload data
        });
      }
    } else {
      this.setState({ applingVoucher: false, validatedVoucher: null }, () => {
        this.handleOverviewChange(null); //reload data
      });
      this.props.app.alertController.showAPIErrorAlert(null, verifyResp);
    }
  }
  async _calculateOrderValues(data) {
    //Generate request
    const user = this._getCurrentUser();
    //Make request
    const resp = await this.props.app.license.productOrder.preOrder({
      items: [
        {
          productID: data.selectedProduct?.id,
          quantity: data.quantity,
          unitValue: data.chargingAmount,
          shippingCharges: data.shippingAmount,
          taxPercentage: this._getTaxPercentage(),
          shouldUseBulkPricing: !this._isNormalUser() && !this.props.app.isAdmin(),
        },
      ],
      shippingInfo: {
        street0: 'data.street0',
        street1: 'data.street1',
        city: 'data.city',
        postalCode: 'data.postalCode',
        province: 'data.province',
        country: 'data.country',
      },
      user: { id: user.id },
      voucherID: this.state.validatedVoucher ? this.state.validatedVoucher.voucherID : null,
    });
    if (!this._isMounted) return null;
    //Response
    console.debug('Pre Order resp:', resp);
    if (resp.statusCode == 200 && resp.body) return resp.body;
    else {
      this.setState({
        stepper: { ...this.state.stepper, current: STEPS.RESULT, status: 'error' },
        isLoading: false,
        payload: { ...this.state.payload, error: resp.body || 'Unknown error!' },
      });
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return null;
    }
  }

  /* Pre order debounce */
  _getTaxPercentage() {
    return this.props.app.sharedCache().getTenantConfig().taxRate;
  }
  async _scheduleCalculateOrderValues(data) {
    //cleanup previous
    if (this.calculateDebounce) {
      clearTimeout(this.calculateDebounce);
      this.calculateDebounce = null;
    }
    //
    return new Promise((resolve) => {
      this.calculatePromisesCallbacks.push(resolve);
      this.calculateDebounce = setTimeout(async () => {
        const resp = await this._calculateOrderValues(data);
        this.calculatePromisesCallbacks.forEach((p) => p(resp));
        this.calculatePromisesCallbacks = [];
      }, 300);
    });
  }
}
